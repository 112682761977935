import { FlowEditorSDK, OnEventFn } from '@wix/yoshi-flow-editor';

export const onEvent: OnEventFn = async (event, editorSDK) => {
  if (event.eventType === 'componentDeleted') {
    const components = await getAllCommentsComponents(editorSDK);
    console.log('components', components);
    if (components.length === 0) {
      await editorSDK.application.uninstall('', {
        openConfirmation: false,
      });
    }
  }
};

const getAllCommentsComponents = async (editorSDK: FlowEditorSDK) => {
  const appDefId = await editorSDK.document.info.getAppDefinitionId('token');
  const appData = await editorSDK.tpa.app.getDataByAppDefId('', appDefId);
  const appComponents = await editorSDK.tpa.app.getAllCompsByApplicationId(
    '',
    appData.applicationId,
  );
  return appComponents ?? [];
};
