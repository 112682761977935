import { ComponentRef, ResponsiveLayout } from '@wix/editor-platform-sdk-types';
import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const setResponsiveness = async ({
  editorSDK,
  compRef,
}: {
  editorSDK: FlowEditorSDK;
  compRef: ComponentRef;
}) => {
  try {
    const oldResponsiveLayout = await editorSDK.document.responsiveLayout.get('token', {
      componentRef: compRef,
    });

    const newComponentLayouts = oldResponsiveLayout.componentLayouts.map((componentLayout) => {
      return {
        ...componentLayout,
        maxWidth: { type: 'px', value: 980 },
        width: { type: 'percentage', value: 100 },
        height: { type: 'auto' },
      };
    });

    const newItemLayouts = oldResponsiveLayout.itemLayouts.map((itemLayout) => {
      if (itemLayout.type === 'GridItemLayout') {
        return {
          ...itemLayout,
          justifySelf: 'center',
          alignSelf: 'center',
          margins: {
            top: { type: 'percentage', value: 0 },
            left: { type: 'percentage', value: 0 },
            right: { type: 'percentage', value: 0 },
            bottom: { type: 'percentage', value: 0 },
          },
        };
      }
      return itemLayout;
    });

    const newResponsiveLayout = {
      ...oldResponsiveLayout,
      componentLayouts: newComponentLayouts,
      itemLayouts: newItemLayouts,
    } as ResponsiveLayout;

    await editorSDK.document.responsiveLayout.update('token', {
      componentRef: compRef,
      responsiveLayout: newResponsiveLayout,
    });
  } catch (e) {
    console.log('Failed to set responsiveness', e);
    return Promise.resolve();
  }
};
