import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { createPublicDataStore } from '~/services/public-data-service/public-data-service';
import { getIsBlogComments } from '../components/CommentsWidget/controller/get-is-blog-comments';
import { setResponsiveness } from './set-responsiveness';
import { setWidgetCategoryId } from './set-widget-category-id';
import { tryFixWidgetCategory } from './try-fix-widget-category';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  platformOptions,
  flowAPI,
) => {
  if (getIsBlogComments(appDefinitionId)) {
    return;
  }
  await tryFixWidgetCategory({ editorSDK, flowAPI });

  editorSDK.addEventListener('widgetAdded', async (event) => {
    const compId = event.detail.componentRef.id;
    const publicDataStore = await createPublicDataStore({
      compId,
      editorSDK,
    });

    await setWidgetCategoryId({
      publicDataStore,
      editorSDK,
      flowAPI,
    });

    await setResponsiveness({ compRef: event.detail.componentRef, editorSDK });
  });
};
