import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import {
  PublicDataStore,
  createPublicDataStore,
} from '~/services/public-data-service/public-data-service';
import { keyBy, mapValues } from 'lodash';
import { WIX_COMMENTS_WIDGET_APP_DEF_ID } from '~/constants/app-def-ids';

// creates a snapshot of the public data store for each widget
export const getPublicDataStoreSnapshot = async ({
  editorSDK,
  flowAPI,
}: {
  editorSDK: FlowEditorSDK;
  flowAPI: EditorScriptFlowAPI;
}) => {
  let storesById: Record<string, PublicDataStore> = {};

  try {
    const widgetComponents =
      (await editorSDK.document.tpa.app.getAllComps('token', WIX_COMMENTS_WIDGET_APP_DEF_ID)) ?? [];

    const widgetCompIds = widgetComponents.map((c) => ({ id: c.id }));

    const publicDataStores = await Promise.all(
      widgetCompIds.map(async ({ id }) => ({
        id,
        store: await createPublicDataStore({ compId: id, editorSDK }),
      })),
    );

    storesById = mapValues(keyBy(publicDataStores, 'id'), 'store');
  } catch (e) {
    console.log('Failed to get public data store snapshot', e);
    flowAPI.errorMonitor?.captureException(
      e instanceof Error ? e : new Error('Failed to get public data store snapshot'),
      { tags: { context: 'getPublicDataStoreSnapshot' } },
    );
  }
  return storesById;
};
