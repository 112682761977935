import { GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { WIX_COMMENTS_WIDGET_COMP_ID } from '../constants/app-def-ids';

import { getIsBlogComments } from '../components/CommentsWidget/controller/get-is-blog-comments';

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  _contextParams,
  flowAPI,
) => {
  const { t } = flowAPI.translations;

  if (getIsBlogComments(flowAPI.environment.appDefinitionId)) {
    return appManifestBuilder.build();
  }

  return appManifestBuilder
    .configureWidget(WIX_COMMENTS_WIDGET_COMP_ID, (widgetBuilder) => {
      widgetBuilder.set({ nickname: 'wixComments' });

      widgetBuilder.behavior().set({ duplicatable: false });

      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('gfpp.manage-comments'),
          onClick: () => {
            editorSDK.editor.openDashboardPanel('token', {
              url: 'comments',
              closeOtherPanels: true,
            });
          },
        })
        .set('mainAction2', { behavior: 'DEFAULT1' });
    })
    .configureController('wix-comments', (controllerBuilderFn) => {
      controllerBuilderFn.exports().set({
        members: {
          setResourceId: {
            kind: 'function',
            description: 'Sets a custom resource ID for comments widget',
            params: [
              {
                name: 'resourceId',
                description: 'string',
              },
            ],
          },
          setIsLocked: {
            kind: 'function',
            description: 'Sets locked state for comments widget',
            params: [
              {
                name: 'isLocked',
                description: 'boolean',
              },
            ],
          },
        },
      });
    })
    .build();
};
